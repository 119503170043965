import React, {useEffect, useState} from 'react';
import styles from './MainWrapper.module.scss';

import Map from "../Map/Map";
import {Header} from "../../components";
import MainPage from "../MainPage/MainPage";
import Article from "../Article/Article";
import {useTranslation} from "react-i18next";
import ArticleAbout from "../ArticleAbout/ArticleAbout";
import ArticleWhy from "../ArticleWhy/ArticleWhy";
import ArticleOffer from "../ArticleOffer/ArticleOffer";
import ArticleLoret from "../ArticleLoret/ArticleLoret";

const MainWrapper = () => {

    const [booking, setBooking] = useState(false);
    const [article, setArticle] = useState(false);
    const [catType, setCarType] = useState(1);
    const {i18n} = useTranslation()

    useEffect(() => {
        if (booking) {
            setArticle(false)
        }
    }, [booking])
    useEffect(() => {
        if (article) {
            setBooking(false)
        }
    }, [article,])

    useEffect(() => {
        let lang = localStorage.getItem("lang")
        if (lang !== null) {
            i18n.changeLanguage(lang)
        }
    }, [i18n])

    return (
        <div className={!booking && styles['wrapper']}>
            {!booking ?

                !article ?
                    <>
                        <Header setBooking={setBooking} setArticle={setArticle}/>
                        <MainPage setBooking={setBooking} setCarType={setCarType} setArticle={setArticle}/>
                    </>
                    :
                    article === "routes"?
                    <>
                        <Header setBooking={setBooking} setArticle={setArticle}/>
                        <Article setBooking={setBooking} setCarType={setCarType}/>
                    </>
                        : article === "about"?
                            <>
                                <Header setBooking={setBooking} setArticle={setArticle}/>
                                <ArticleAbout setBooking={setBooking}/>
                            </>
                            : article === "offer"?
                                <>
                                    <Header setBooking={setBooking} setArticle={setArticle}/>
                                    <ArticleOffer setBooking={setBooking}/>
                                </>
                                : article === "why"?
                                    <>
                                        <Header setBooking={setBooking} setArticle={setArticle}/>
                                        <ArticleWhy setBooking={setBooking}/>
                                    </>
                                :article === "loret"?
                                        <>
                                            <Header setBooking={setBooking} setArticle={setArticle}/>
                                            <ArticleLoret setBooking={setBooking}/>
                                        </>
                                        :<></>
                :
                <Map setBooking={setBooking} catType={catType}/>
            }
        </div>
    );
};

export default MainWrapper;