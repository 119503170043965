import React, {useState} from 'react';
import styles from './TestimonialsCarousel.module.scss';
import {useTranslation} from "react-i18next";



const TestimonialsCarousel = () => {
    const {t} = useTranslation('common');

    const testimonials = [
        {
            text:`"${t('testim.testimonial1.text')}"`,
            author: t('testim.testimonial1.user')
        },
        {
            text:`"${t('testim.testimonial2.text')}"`,
            author: t('testim.testimonial2.user')
        },
        {
            text:`"${t('testim.testimonial3.text')}"`,
            author: t('testim.testimonial3.user')
        },
        {
            text:`"${t('testim.testimonial4.text')}"`,
            author: t('testim.testimonial4.user')
        },
        {
            text:`"${t('testim.testimonial5.text')}"`,
            author: t('testim.testimonial5.user')
        },
        {
            text:`"${t('testim.testimonial6.text')}"`,
            author: t('testim.testimonial6.user')
        },
        {
            text:`"${t('testim.testimonial7.text')}"`,
            author: t('testim.testimonial7.user')
        }
    ]


    const [current, setCurrent] = useState(0)

    return (
        <div className={`${styles["carousel"]}`}>
            <div className={`${styles["carousel__buttons"]}`}>
                <button className={current>0 && styles["active"]} onClick={()=>{current>0 && setCurrent(current-1)}}> &#10094; </button>
                <button className={current<testimonials.length-1 && styles["active"]} onClick={()=>{current<testimonials.length-1 && setCurrent(current+1)}}> &#10095; </button>
                <p>{current+1}<span>/{testimonials.length}</span></p>
            </div>
            <div className={`${styles["carousel__content"]}`}>
                <img src="./qout_in.svg" alt=""/>
                <p>{testimonials[current].text}</p>
                <p>{testimonials[current].author}</p>
                <img src="./qout_out.svg" alt=""/>
            </div>
        </div>
    );
};

export default TestimonialsCarousel;