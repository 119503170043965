import React, {useEffect, useState} from 'react';
import styles from "./OrderContainer.module.scss"
import {useTranslation} from "react-i18next";
import Select from "react-select";


const options = [
    { value: 'en', label: <img src={"./gb.svg"}/> },
    { value: 'es', label: <img src={"./es.svg"}/> },
    { value: 'ru', label: <img src={"./sru.svg"}/> },
];


const OrderContainer = ({setBooking,children}) => {

    const { t, i18n } = useTranslation("booking")

    const [lang, setlang] = useState(i18n.language==="en"?options[0]:i18n.language==="es"?options[1]:options[2])

    useEffect(()=>{
        let lang = localStorage.getItem("lang")
        if(lang !== null){
            setlang(i18n.language==="en"?options[0]:i18n.language==="es"?options[1]:options[2])
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(lang.value)
    },[lang])


    return (
        <div className={`${styles["orderContainer"]}`}>
            <div className={`${styles["orderContainer__header"]}`}>
                <img src="./logo.svg" alt="logo" onClick={()=>{setBooking(false)}}/>
                <div className={`${styles["orderContainer__actions"]}`}>
                    <div className={`${styles["orderContainer__hideMob"]}`}>
                    <Select
                        value={lang}
                        onChange={(e)=> {
                            setlang(e);
                            localStorage.setItem("lang", e.value)
                        }}
                        options={options}
                        isSearchable={false}
                    />

                    </div>
                    <button  onClick={()=>{setBooking(false)}}>{t("help")}</button>
                </div>
            </div>
            <div className={`${styles["orderContainer__content"]}`}>
                {children}
            </div>
        </div>
    );
};

export default OrderContainer;