import React from 'react';
import styles from "./OrderStepTwo.module.scss";
import HumsIcon from "../../assets/icons/HumsIcon";
import BagIcon from "../../assets/icons/BagIcon";
import {useTranslation} from "react-i18next";

const OrderStepTwo = ({
                          carType,
                          setCarType,
                          date,
                          setDate,
                          time,
                          setTime,
                          animal,
                          setAnimal,
                          addition,
                          setAddition,
                          price,
                          priceVan
                      }) => {
    const {t, i18n} = useTranslation("booking")

    return (
        <div className={`${styles['orderStepTwo']}`}>
            <h2 className={`${styles['orderStepTwo__sectionTitle']}`}>{t("cars.title")}</h2>
            <div className={`${styles['orderStepTwo__selectCar']}`}>
                <div
                    className={`${styles['orderStepTwo__car']} ${carType === 1 && styles['selected']}`}
                    onClick={() => {
                        setCarType(1)
                    }}>
                    <div className={`${styles['orderStepTwo__carInfoTop']}`}>
                        <img src="./standartCar.png" alt=""/>
                        <div>
                            <h4>{t("cars.carStandart")}</h4>
                            <div className={`${styles['orderStepTwo__carDetail']}`}>
                                <div>
                                    <HumsIcon/>
                                    <span>1 - 4</span>
                                </div>
                                <div>
                                    <BagIcon/>
                                    <span>1 - 4</span>
                                </div>
                            </div>
                        </div>
                        <h4 className={`${styles['orderStepTwo__carPrice']}`}>{price}€</h4>

                    </div>
                    <p>{t("cars.carStandartDescription")}</p>
                </div>
                <div
                    className={`${styles['orderStepTwo__car']} ${carType === 2 && styles['selected']}`}
                    onClick={() => {
                        setCarType(2)
                    }}>
                    <div className={`${styles['orderStepTwo__carInfoTop']}`}>
                        <img src="./vanCar.png" alt=""/>
                        <div>
                            <h4>{t("cars.carVan")}</h4>
                            <div className={`${styles['orderStepTwo__carDetail']}`}>
                                <div>
                                    <HumsIcon/>
                                    <span>1 - 7</span>
                                </div>
                                <div>
                                    <BagIcon/>
                                    <span>1 - 7</span>
                                </div>
                            </div>
                        </div>
                        <h4 className={`${styles['orderStepTwo__carPrice']}`}>{priceVan}€</h4>

                    </div>
                    <p>{t("cars.carVanDescription")}</p>
                </div>
            </div>
            <p className={`${styles['orderStepTwo__sectionDescription']}`}>{t("cars.description")}</p>


            <h2 className={`${styles['orderStepTwo__sectionTitle']}`}>{t("datePick.title")}</h2>
            <div className={`${styles['orderStepTwo__timepicker']}`}>
                <label>
                    <input type="date" value={date} onChange={(e) => {
                        setDate(e.target.value)
                    }}/>
                </label>
                <label>
                    <input type="time" value={time} onChange={(e) => {
                        setTime(e.target.value)
                    }}/>
                </label>
            </div>
            <p className={`${styles['orderStepTwo__sectionDescription']}`}>{t("datePick.description")}</p>


            <h2 className={`${styles['orderStepTwo__sectionTitle']}`}>{t("extra.title")}</h2>
            <div className={`${styles['orderStepTwo__extra']}`}>
                <p>{t("extra.baby")}</p>
                <div className={`${styles['orderStepTwo__extraSelector']}`}>
                    <div className={`${animal === true && styles['selected']}`} onClick={() => {
                        setAnimal(true)
                    }}>{t("extra.yes")}
                    </div>
                    <div className={`${animal === false && styles['selected']}`} onClick={() => {
                        setAnimal(false)
                    }}>{t("extra.no")}
                    </div>
                </div>
            </div>

            <h2 className={`${styles['orderStepTwo__sectionTitle']}`}>{t("addition.title")}</h2>
            <textarea cols="30" rows="10" placeholder={t("addition.placeholder")}
                      className={`${styles['orderStepTwo__addition']}`} value={addition} onChange={(e) => {
                setAddition(e.target.value)
            }}></textarea>


        </div>
    );
};

export default OrderStepTwo;