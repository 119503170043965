import React from 'react';

const PriceIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 25.6667L11.6667 14L4.66669 25.6667" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 32.6667C15.5327 32.6667 18.6667 29.5326 18.6667 25.6667H4.66669C4.66669 29.5326 7.80069 32.6667 11.6667 32.6667Z" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M51.3333 25.6667L44.3333 14L37.3333 25.6667" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.3333 32.6667C48.1993 32.6667 51.3333 29.5326 51.3333 25.6667H37.3333C37.3333 29.5326 40.4673 32.6667 44.3333 32.6667Z" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28 7V49" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 14H28H44.3334" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 14H28H44.3334" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.3334 49H28H11.6667" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default PriceIcon;