import React, {useState} from 'react';
import styles from './Input.module.scss';

const Input = ({value, setValue, icon, placeholder}) => {
    const [focused, setFocused] = useState(false);
    return (
        <label>
            <span className={`${styles["placeholder"]} ${focused&&styles["focused"]}`}>{placeholder}</span>
            <input type="text"
                   value={value}
                   onChange={(e) => {
                       setValue(e.target.value)
                   }}
                   onBlur={() => {
                       if(value.length < 1){
                           setFocused(false)
                       }
                   }}
                   onFocus={() => {
                       setFocused(true)
                   }}
            />
            <span className={`${styles["icon"]}`}>
                {icon}
            </span>

        </label>
    );
};

export default Input;