import React from 'react';
import styles from './Offer.module.scss';
import {useTranslation} from "react-i18next";




const Offer = ({setArticle}) => {
    const {t} = useTranslation('common');

    return (
        <div className={`${styles['offer']}`}>
            <div>
                <h3>{t('offer.first.title')}</h3>
                <p>{t('offer.first.text')}</p>
                {/*<button onClick={()=>{setArticle("about")}}>{t('learnMore')}</button>*/}
            </div>
            <div>
                <h3>{t('offer.second.title')}</h3>
                <div>
                    <div className={`${styles['offer__destination']}`} onClick={()=>{setArticle("about")}}>
                        <span>Andora</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['offer__destination']}`} onClick={()=>{setArticle("offer")}}>
                        <span>Salou</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['offer__destination']}`} onClick={()=>{setArticle("loret")}}>
                        <span>Lloret de Mar</span>
                        <span>→</span>
                    </div>
                </div>
                {/*<button onClick={()=>{setArticle("offer")}}>{t('learnMore')}</button>*/}
            </div>
        </div>
    );
};

export default Offer;