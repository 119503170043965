import React from 'react';

const BagIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 5.25H3.75C3.33579 5.25 3 5.58579 3 6V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V6C15 5.58579 14.6642 5.25 14.25 5.25Z" fill="#333333" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 8.625V11.625" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M5.625 15V16.5" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.375 15V16.5" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 8.625V11.625" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M7.125 1.5H10.875" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 1.5V5.25" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default BagIcon;