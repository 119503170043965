import React from 'react';
import styles from "./OrderStepThree.module.scss";
import {useTranslation} from "react-i18next";

const OrderStepThree = ({
                            setStep,
                            name,
                            setName,
                            lastName,
                            setLastName,
                            phoneNumber,
                            setPhoneNumber,
                            email,
                            setEmail,
                            flightNumber,
                            setFlightNumber,
                            send,
                            infoBlock
                        }) => {
    const {t, i18n} = useTranslation("booking")

    return (
        <div className={`${styles['orderStepThree']}`}>

            <h2 className={`${styles['orderStepThree__sectionTitle']}`}>{t("infosForm.title")}</h2>
            <form className={`${styles['orderStepThree__form']}`} onSubmit={(e) => {
                e.preventDefault();
                send()
                document.getElementById('submit').disabled=true;
                document.getElementById('submit').innerText="Booking...";
            }}>
                <div className={`${styles['orderStepThree__formRow']}`}>
                    <input type="text" placeholder={t("infosForm.name")} value={name} onChange={(e) => {
                        setName(e.target.value)
                    }} required/>
                    <input type="text" placeholder={t("infosForm.lName")} value={lastName} onChange={(e) => {
                        setLastName(e.target.value)
                    }} required/>
                </div>
                <input type="number" placeholder={t("infosForm.phone")} value={phoneNumber} onChange={(e) => {
                    setPhoneNumber(e.target.value)
                }} required/>
                <input type="email" placeholder={"Email"} value={email} onChange={(e) => {
                    setEmail(e.target.value)
                }} required/>
                <input type="text" placeholder={t("infosForm.flyN")} value={flightNumber} onChange={(e) => {
                    setFlightNumber(e.target.value)
                }}/>
                <p className={`${styles['orderStepThree__sectionDescription']}`}>{t("infosForm.description")}</p>
                {infoBlock}



                <div className={`${styles['orderStepThree__nextStepWrap']}`}>
                    <button className={`${styles['orderStepThree__nextStep']}`} onClick={() => {
                        setStep(2)
                    }}> &lt; </button>
                    <button className={`${styles['orderStepThree__nextStep']}`} type={"submit"}  id="submit">Book a ride ></button>
                </div>
            </form>

        </div>
    );
};

export default OrderStepThree;