import React, {useState} from 'react';
import styles from './Article.module.scss'
import {useTranslation} from "react-i18next";
import PopularRouteArticle from "../PopularRouteArticle/PopularRouteArticle";

const routes = [
    {
        from: "Barcelona",
        to: "Lloret de Mar",
        price:"102 €",
    },
    {
        from: "Barcelona",
        to: "Reus",
        price:"151 €",
    },
    {
        from: "Barcelona",
        to: "Valencia",
        price:"456 €",
    }
    ,    {
        from: "Barcelona",
        to: "Sitges",
        price:"61 €",
    }
    ,
    {
        from: "Barcelona",
        to: "Tarragona",
        price:"130 €",
    }
    ,
    {
        from: "Barcelona",
        to: "Salou",
        price:"154 €",
    },
    {
        from: "Barcelona",
        to: "Nice",
        price:"863 €",
    },
    {
        from: "Barcelona",
        to: "Begur",
        price:"172 €",
    },
    {
        from: "Barcelona",
        to: "Girona",
        price:"144 €",
    },
    {
        from: "Barcelona",
        to: "Madrid",
        price:"816 €",
    },
    {
        from: "Barcelona",
        to: "Andorra",
        price:"259 €",
    },
    {
        from: "Barcelona",
        to: "Lourdes",
        price:"731 €",
    },
    {
        from: "Barcelona",
        to: "Alicante",
        price:"688 €",
    },
    {
        from: "Barcelona",
        to: "Cambrils",
        price:"165 €",
    },
    {
        from: "Barcelona",
        to: "Perpignan",
        price:"251 €",
    },
    {
        from: "Barcelona",
        to: "Platja D'aro",
        price:"149 €",
    },
    {
        from: "Barcelona",
        to: "Girona Airport",
        price:"122 €",
    },
    {
        from: "Barcelona",
        to: "Calella de Palafrugell",
        price:"165 €",
    }

]

const Article = ({setBooking, setCarType}) => {
    const [direction, setDirection] = useState(false)
    const { t } = useTranslation("article")


    return direction === false? (
        <div className={`${styles['article']}`}>
            <div className={`${styles['article__banner']}`} style={{background: "url('andora-min.png')"}}>
                Popular Routes
            </div>
            <div className={`${styles['article__block']}`}>
                <h4 className={`${styles['article__title']}`}>{t['dest']}</h4>
                <div className={`${styles['article__destinations']}`}>
                    {routes.map(({from, to ,price}, index )=> (
                        <div key={index} className={`${styles['article__destination']}`} onClick={()=>{setDirection(index);}}>
                            <span>{from}</span>
                            <span>→</span>
                            <span>{to}</span>
                        </div>
                    ))}

                </div>
            </div>
            <div className={`${styles['article__block']}`}>
                <h4 className={`${styles['article__title']}`}>{t['place']}</h4>
                <div className={`${styles['article__destinations']}`}>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Barcelona el Prat</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>PortAventura</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Montserrat</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Reus Airport</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Girona Airport</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Barcelona cruise port</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Valencia Airport</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Salou</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Lloret de Mar</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Badalona</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Tarragona</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Valencia</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Girona</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Alicante</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Perpignan</span>
                        <span>→</span>
                    </div>
                    <div className={`${styles['article__destination']}`} onClick={()=>{setBooking(true)}}>
                        <span>Platja d'Aro</span>
                        <span>→</span>
                    </div>
                </div>
            </div>
            <div className={`${styles['article__footer']}`}>
                <img src="./logo_white.svg" alt=""/>

                <h5>Book your private cab with barcelonataxitransfer today for a comfortable
                    and reliable journey across Spain. Don't miss out on our convenient
                    service - reserve your ride now!</h5>
                <p>© 2023 barcelonataxitransfer. All right reserved.</p>
            </div>
        </div>
    ):
        <PopularRouteArticle from={routes[direction].from} to={routes[direction].to} price={routes[direction].price} setBooking={setBooking} setCarType={setCarType}/>
};

export default Article;