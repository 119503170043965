import React from 'react';
import styles from './PopularRouteArticle.module.scss'
import {useTranslation} from "react-i18next";
import HumsIcon from "../../assets/icons/HumsIcon";
import BagIcon from "../../assets/icons/BagIcon";

const PopularRouteArticle = ({setBooking, from, to, price, setCarType}) => {
    const { t } = useTranslation("common")

    return (
        <div className={`${styles['article']}`}>
            <div className={`${styles['article__banner']}`} style={{background: "url('proutes.webp')"}}>
                <h1>{t('transfer')} {from} {to}</h1>
                <p>{t('bookiIt')} {price}</p>
                <button className={`${styles['article__book']}`} onClick={()=>{setBooking(true)}}>{t("book")}</button>
            </div>
            <div className={`${styles['article__cardWrapper']}`}>
                <div className={`${styles['article__card']}`}>
                    <h4>{t('wellcome.step1.title')}</h4>
                    <p>{t('wellcome.step1.description')}</p>
                    <span className={`${styles['article__step']}`}>1</span>
                </div>
                <div className={`${styles['article__card']}`}>
                    <h4>{t('wellcome.step2.title')}</h4>
                    <p>{t('wellcome.step2.description')}</p>
                    <span className={`${styles['article__step']}`}>2</span>
                </div>
                <div className={`${styles['article__card']}`}>
                    <h4>{t('wellcome.step3.title')}</h4>
                    <p>{t('wellcome.step3.description')}</p>
                    <span className={`${styles['article__step']}`}>3</span>
                </div>
            </div>
            {/*<h2>{t('from')} {from} {t('to')} {to}: {t('chooseRide')}</h2>*/}
            {/*<div className={`${styles['article__cars']}`}>*/}
            {/*    <div className={`${styles['article__car']}`} onClick={()=>{setCarType(1);setBooking(true)}}>*/}
            {/*        <h5>{t('wellcome.sizeStandart.title')}</h5>*/}
            {/*        <img src="./standartCar.png" alt="standart"/>*/}
            {/*        <div className={`${styles['article__carDetail']}`}>*/}
            {/*            <div>*/}
            {/*                <HumsIcon/>*/}
            {/*                <span>1 - 4</span>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <BagIcon/>*/}
            {/*                <span>1 - 4</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div  className={`${styles['article__carDescription']}`}>*/}
            {/*            <p>{t('wellcome.sizeStandart.description')}</p>*/}
            {/*            <span>+</span>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*    <div className={`${styles['article__car']}`} onClick={()=>{setCarType(2);setBooking(true)}}>*/}
            {/*        <h5>{t('wellcome.sizeVan.title')}</h5>*/}
            {/*        <img src="./vanCar.png" alt="van"/>*/}
            {/*        <div className={`${styles['article__carDetail']}`}>*/}
            {/*            <div>*/}
            {/*                <HumsIcon/>*/}
            {/*                <span>1 - 7</span>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <BagIcon/>*/}
            {/*                <span>1 - 7</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div  className={`${styles['article__carDescription']}`}>*/}
            {/*            <p>{t('wellcome.sizeVan.description')}</p>*/}
            {/*            <span>+</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={`${styles['article__footer']}`}>
                <img src="./logo_white.svg" alt=""/>

                <h5>Book your private cab with barcelonataxitransfer today for a comfortable
                    and reliable journey across Spain. Don't miss out on our convenient
                    service - reserve your ride now!</h5>
                <p>© 2023 barcelonataxitransfer. All right reserved.</p>
            </div>
        </div>
    );
};

export default PopularRouteArticle;