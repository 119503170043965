import React from 'react';

const Shit = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1667 23.3333C26.677 23.3333 30.3333 19.677 30.3333 15.1667C30.3333 10.6563 26.677 7 22.1667 7C17.6563 7 14 10.6563 14 15.1667C14 19.677 17.6563 23.3333 22.1667 23.3333Z" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M38.5 9.33334C38.5 9.33334 41.125 14.5833 38.5 21" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M46.6667 4.66666C46.6667 4.66666 51.9167 14.1167 46.6667 25.6667" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.66669 47.6V49H39.6667V47.6C39.6667 42.3729 39.6667 39.7593 38.6495 37.7628C37.7546 36.0066 36.3268 34.5787 34.5706 33.6839C32.5741 32.6667 29.9605 32.6667 24.7334 32.6667H19.6C14.3729 32.6667 11.7593 32.6667 9.76278 33.6839C8.0066 34.5787 6.57877 36.0066 5.68396 37.7628C4.66669 39.7593 4.66669 42.3729 4.66669 47.6Z" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default Shit;