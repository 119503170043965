import React from 'react';
import styles from './OrderFinish.module.scss';
import {useTranslation} from "react-i18next";

const OrderFinish = () => {
    const { t, i18n } = useTranslation("booking")

    return (
        <div className={`${styles['orderFinish']}`}>
            <img src="./Check.svg" alt="Nice"/>
            <h2 className={`${styles['orderFinish__sectionTitle']}`}>{t("done.title")}</h2>
            <p className={`${styles['orderFinish__sectionDescription']}`}>{t("done.description")}</p>
        </div>
    );
};

export default OrderFinish;