import React from 'react';

const PunctualityIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.9998 51.7221C39.8127 51.7221 49.3888 42.146 49.3888 30.3332C49.3888 18.5205 39.8127 8.94434 27.9998 8.94434C16.1871 8.94434 6.61096 18.5205 6.61096 30.3332C6.61096 42.146 16.1871 51.7221 27.9998 51.7221Z" stroke="#070A11" stroke-width="3" stroke-linejoin="round"/>
            <path d="M27.7193 17.9125L27.7179 30.7561L36.7856 39.8238" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.66663 10.5L12.8333 4.66669" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M51.3333 10.5L43.1666 4.66669" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default PunctualityIcon;