import React from 'react';
import ReactDOM from 'react-dom/client';
import {MainWrapper} from "./containers";
import './css/fonts.css';
import './css/global.css';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import navigation_en from "./translations/en/navigation.json";
import article_en from "./translations/en/article.json";
import booking_en from "./translations/en/booking.json";

import common_es from "./translations/es/common.json";
import navigation_es from "./translations/es/navigation.json";
import article_es from "./translations/es/article.json";
import booking_es from "./translations/es/booking.json";

import common_ru from "./translations/ru/common.json";
import navigation_ru from "./translations/ru/navigation.json";
import article_ru from "./translations/ru/article.json";
import booking_ru from "./translations/ru/booking.json";


i18next.init({
    interpolation: {escapeValue: false},
    lng: 'en',
    resources: {
        en: {
            common: common_en,
            navigation: navigation_en,
            article: article_en,
            booking: booking_en,
        },
        es: {
            common: common_es,
            navigation: navigation_es,
            article: article_es,
            booking: booking_es,
        },
        ru: {
            common: common_ru,
            navigation: navigation_ru,
            article: article_ru,
            booking: booking_ru,
        },
    },// React already does escaping
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <MainWrapper/>
        </I18nextProvider>
    </React.StrictMode>
);
