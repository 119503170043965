import React from 'react';
import styles from './Welcome.module.scss';
import HumsIcon from "../../../../assets/icons/HumsIcon";
import BagIcon from "../../../../assets/icons/BagIcon";
import {useTranslation} from "react-i18next";

const Welcome = ({setCarType, setBooking}) => {
    const {t} = useTranslation('common');

    return (
        <div className={`${styles['welcome']}`}>
            <div className={`${styles['welcome__block']} ${styles['welcome__block_left']}`}>
                <h2>
                        {t('wellcome.cabType')}
                </h2>
                <div className={`${styles['welcome__cars']}`}>
                    <div className={`${styles['welcome__car']}`} onClick={()=>{setCarType(1);setBooking(true)}}>
                        <h5>{t('wellcome.sizeStandart.title')}</h5>
                        <img src="./standartCar.png" alt="standart"/>
                        <div className={`${styles['welcome__carDetail']}`}>
                            <div>
                                <HumsIcon/>
                                <span>1 - 4</span>
                            </div>
                            <div>
                                <BagIcon/>
                                <span>1 - 4</span>
                            </div>
                        </div>
                        <div  className={`${styles['welcome__carDescription']}`}>
                            <p>{t('wellcome.sizeStandart.description')}</p>
                            <span>+</span>
                        </div>

                    </div>
                    <div className={`${styles['welcome__car']}`} onClick={()=>{setCarType(2);setBooking(true)}}>
                        <h5>{t('wellcome.sizeVan.title')}</h5>
                        <img src="./vanCar.png" alt="van"/>
                        <div className={`${styles['welcome__carDetail']}`}>
                            <div>
                                <HumsIcon/>
                                <span>1 - 7</span>
                            </div>
                            <div>
                                <BagIcon/>
                                <span>1 - 7</span>
                            </div>
                        </div>

                        <div  className={`${styles['welcome__carDescription']}`}>
                            <p>{t('wellcome.sizeVan.description')}</p>
                            <span>+</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['welcome__block']} ${styles['welcome__block_right']}`}>
                <h3>
                    ¡Bienvenido a España! <br/>
                    Welcome to Spain! <br/>
                    Добро пожаловать в Испанию!
                </h3>
                <div  className={`${styles['welcome__cards']}`}>
                    <div className={`${styles['welcome__cardWrapper']}`}>
                        <div className={`${styles['welcome__card']}`}>
                            <h4>{t('wellcome.step1.title')}</h4>
                            <p>{t('wellcome.step1.description')}</p>
                            <span className={`${styles['welcome__step']}`}>1</span>
                        </div>
                        <div className={`${styles['welcome__card']}`}>
                            <h4>{t('wellcome.step2.title')}</h4>
                            <p>{t('wellcome.step2.description')}</p>
                            <span className={`${styles['welcome__step']}`}>2</span>
                        </div>
                    </div>
                    <div className={`${styles['welcome__cardWrapper']}`}>
                        <div className={`${styles['welcome__card']}`}>
                            <h4>{t('wellcome.step3.title')}</h4>
                            <p>{t('wellcome.step3.description')}</p>
                            <span className={`${styles['welcome__step']}`}>3</span>
                        </div>
                        <div className={`${styles['welcome__card']} ${styles['welcome__card_green']}`}>
                            <h4>{t('wellcome.step4.title')}</h4>
                            <div  className={`${styles['welcome__social']}`}>
                                <a href="https://wa.me/34647180077"><img src="./w.svg" alt=""/></a>
                                <a href="viber://contact?number=%2B34672891758"><img src="./v.svg" alt=""/></a>
                                <a href="https://t.me/skyline77722"><img src="./t.svg" alt=""/></a>
                                <a href="https://m.facebook.com/messages/compose?ids=USER_NAME_OR_ID"><img src="./m.svg" alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;