import React from 'react';
import Welcome from "./sections/Wellcome/Welcome";
import Offer from "./sections/Offer/Offer";
import Why from "./sections/Why/Why";
import Testimonials from "./sections/Testimonials/Testimonials";
import Contact from "./sections/Contact/Contact";

const MainPage = ({setBooking, setArticle, setCarType}) => {
    return (
        <div>
            <Welcome setCarType={setCarType} setBooking={setBooking}/>
            <Offer setArticle={setArticle}/>
            <Why setBooking={setBooking} setArticle={setArticle}/>
            <Testimonials/>
            <Contact/>
        </div>
    );
};

export default MainPage;