import React from 'react';
import styles from './why.module.scss';
import Shit from "../../../../assets/icons/shit";
import PriceIcon from "../../../../assets/icons/PriceIcon";
import SecurityIcon from "../../../../assets/icons/SecurityIcon";
import PunctualityIcon from "../../../../assets/icons/PunctualityIcon";
import ComfortIcon from "../../../../assets/icons/ComfortIcon";
import {useTranslation} from "react-i18next";

const Why = ({setBooking, setArticle}) => {
    const {t} = useTranslation('common');

    return (
        <div className={`${styles['why']}`}>
            <div className={`${styles['why__top']}`}>
                <div className={`${styles['why__title']}`}>
                    <h3>{t("why.title")}</h3>
                </div>
                <div  className={`${styles['why__info']}`}>

                    <p>{t("why.description")}</p>

                    <button onClick={()=>{setArticle("why")}}>{t("learnMore")}</button>
                </div>
                <div  className={`${styles['why__card']} ${styles['why__card_green']}`} onClick={()=>{setBooking(true)}}>
                    <span> > </span>
                    <h4>{t("why.book.title")}</h4>
                    <p>{t("why.book.description")}</p>
                </div>
            </div>
            <div  className={`${styles['why__bottom']}`}>
                <div className={`${styles['why__card']}`}>
                    <ComfortIcon/>
                    <h4>{t("why.card1.title")}</h4>
                    <p>{t("why.card1.description")}</p>
                </div>
                <div className={`${styles['why__card']}`}>
                    <PunctualityIcon/>
                    <h4>{t("why.card2.title")}</h4>
                    <p>{t("why.card2.description")}</p>
                </div>
                <div className={`${styles['why__card']}`}>
                    <SecurityIcon/>
                    <h4>{t("why.card3.title")}</h4>
                    <p>{t("why.card3.description")}</p>
                </div>
                <div className={`${styles['why__card']}`}>
                    <PriceIcon/>
                    <h4>{t("why.card4.title")}</h4>
                    <p>{t("why.card4.description")}</p>
                </div>
                <div className={`${styles['why__card']}`}>
                    <Shit/>
                    <h4>{t("why.card5.title")}</h4>
                    <p>{t("why.card5.description")}</p>
                </div>
            </div>
        </div>
    );
};

export default Why;