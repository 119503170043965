import React, {useEffect, useState} from 'react';
import styles from './OrderStepOne.module.scss';
import Input from "../Input/Input";
import {useDebounce} from "../../hooks/useDebounce";
import mapboxgl from "mapbox-gl";
import {useTranslation} from "react-i18next";

mapboxgl.accessToken = 'pk.eyJ1Ijoia3J5YWtyeWEiLCJhIjoiY2xqNjBuOTYzMGk5bDNnbnhoeDJuODE2ciJ9.oP0zse110xpxer5HOSHvJw';
const OrderStepOne = ({setFrom, setTo,infoBlock}) => {
    const { t, i18n } = useTranslation("booking")

    const [fromInput, setFromInput] = useState("");
    const [toInput, setToInput] = useState("");
    const [toInputFix, setToInputFix] = useState(false);
    const [fromInputFix, setFromInputFix] = useState(false);

    const debouncedFrom = useDebounce(fromInput, 500)
    const debouncedTo = useDebounce(toInput, 500)

    const [autocomplete, setAutocomplete] = useState(false);

    useEffect(() => {
        if (debouncedFrom.length >= 3 && fromInputFix === false) {
            let conf = {
                accessToken: mapboxgl.accessToken,
                session_token: "1a2b3c4d5e6f7g8h9i",
                lang: "en",
                q: debouncedFrom
            }
            fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/" + conf.q + ".json?country=es%2Cfr%2Cad%2Cbe&proximity=ip&language=en&access_token=pk.eyJ1IjoiZmRhZmlsaXBwb3YiLCJhIjoiY2xqcGVqajhvMjI1MzNkcGpsNGIxOW8yMCJ9.CxHhRfdeXrrFVN_dn3qdMw").then(async r => {
                let result = (await r.json()).features;
                result = result.map(item => {
                    return {...item, for: 1}
                })
                setAutocomplete(result)
            })
        }
    }, [debouncedFrom])
    useEffect(() => {
        if (debouncedTo.length >= 3 && toInputFix === false) {
            let conf = {
                accessToken: mapboxgl.accessToken,
                session_token: "1a2b3c4d5e6f7g8h9i",
                lang: "en",
                q: debouncedTo
            }
            fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/" + conf.q + ".json?country=es%2Cfr%2Cad%2Cbe&proximity=ip&language=en&access_token=pk.eyJ1IjoiZmRhZmlsaXBwb3YiLCJhIjoiY2xqcGVqajhvMjI1MzNkcGpsNGIxOW8yMCJ9.CxHhRfdeXrrFVN_dn3qdMw").then(async r => {
                let result = (await r.json()).features;
                result = result.map(item => {
                    return {...item, for: 2}
                })
                setAutocomplete(result)
            })
        }
    }, [debouncedTo])

    return (
        <div className={`${styles['orderStepOne']}`}>

            <div className={`${styles['orderStepOne__inputs']}`}>
                <img src="./inputbg.png" alt=""/>
                <div>
                    <Input value={fromInput} setValue={(e)=>{setFromInput(e); setFromInputFix(false)}} placeholder={t('pick')}/>
                    <Input value={toInput} setValue={(e)=>{setToInput(e); setToInputFix(false)}}  placeholder={t('drop')}/>
                </div>
            </div>
            <div className={`${styles['orderStepOne__autocomplete']}`}>
                {autocomplete && autocomplete.map((item, index) => (
                    <div key={index} onClick={() => {
                        if (item.for === 1) {
                            setFrom(item);
                            setFromInput(item.place_name_en);
                            setFromInputFix(true);
                            setAutocomplete(false)
                        } else {
                            setTo(item);
                            setToInput(item.place_name_en);
                            setToInputFix(true)
                            setAutocomplete(false)

                        }

                    }}>
                        {item.text_en}
                        <span><br/>{item.place_name_en}</span>
                    </div>
                ))
                }
            </div>
            {infoBlock}
        </div>
    );
};

export default OrderStepOne;