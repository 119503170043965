import React from 'react';
import styles from './ArticleLoret.module.scss'
import {useTranslation} from "react-i18next";

const ArticleLoret = () => {
    const { t } = useTranslation("article")

    return (
        <div className={`${styles['article']}`}>
            <div className={`${styles['article__banner']}`} style={{background: "url('lloret-min.png')"}}>
                {t('loret.title')}
            </div>
            <div className={`${styles['article__block']}`}>
                <p>{t('loret.text')}</p>
            </div>
            <div className={`${styles['article__footer']}`}>
                <img src="./logo_white.svg" alt=""/>

                <h5>Book your private cab with barcelonataxitransfer today for a comfortable
                    and reliable journey across Spain. Don't miss out on our convenient
                    service - reserve your ride now!</h5>
                <p>© 2023 barcelonataxitransfer. All right reserved.</p>
            </div>
        </div>
    );
};

export default ArticleLoret;