import React from 'react';

const HumsIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.125 7.5C8.57475 7.5 9.75 6.32475 9.75 4.875C9.75 3.42525 8.57475 2.25 7.125 2.25C5.67525 2.25 4.5 3.42525 4.5 4.875C4.5 6.32475 5.67525 7.5 7.125 7.5Z" fill="#333333" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.2279 2.625C12.9902 3.08434 13.5001 3.92014 13.5001 4.875C13.5001 5.82986 12.9902 6.66566 12.2279 7.125" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.5 15.3V15.75H12.75V15.3C12.75 13.6198 12.75 12.7798 12.423 12.138C12.1354 11.5735 11.6765 11.1146 11.112 10.827C10.4702 10.5 9.63015 10.5 7.95 10.5H6.3C4.61985 10.5 3.77978 10.5 3.13803 10.827C2.57354 11.1146 2.1146 11.5735 1.82698 12.138C1.5 12.7798 1.5 13.6198 1.5 15.3Z" fill="#333333" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.5 15.75V15.3C16.5 13.6199 16.5 12.7798 16.173 12.1381C15.8854 11.5736 15.4264 11.1147 14.8619 10.827" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default HumsIcon;