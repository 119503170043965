import React from 'react';

const SecurityIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10.7982L28.01 4.66666L49 10.7982V23.3726C49 36.5892 40.5419 47.156 28.003 51.3339C15.4608 47.1561 7 36.5867 7 23.3668V10.7982Z" stroke="#070A11" stroke-width="3" stroke-linejoin="round"/>
            <path d="M28 26.8333C31.2216 26.8333 33.8333 24.2217 33.8333 21C33.8333 17.7783 31.2216 15.1667 28 15.1667C24.7783 15.1667 22.1666 17.7783 22.1666 21C22.1666 24.2217 24.7783 26.8333 28 26.8333Z" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.3333 36.1667C37.3333 31.012 33.1546 26.8333 28 26.8333C22.8453 26.8333 18.6666 31.012 18.6666 36.1667" stroke="#070A11" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default SecurityIcon;