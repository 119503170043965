import React, {useEffect, useRef, useState} from 'react';
import styles from './Map.module.scss';
import OrderContainer from "../../components/OrderContainer/OrderContainer";
import OrderStepOne from "../../components/OrderStepOne/OrderStepOne";
import mapboxgl from 'mapbox-gl';
import OrderStepTwo from "../../components/OrderStepTwo/OrderStepTwo";
import OrderStepThree from "../../components/OrderStepThree/OrderStepThree";
import OrderFinish from "../../components/OrderFinish/OrderFinish";
import Moment from 'react-moment';

mapboxgl.accessToken = 'pk.eyJ1IjoiZmRhZmlsaXBwb3YiLCJhIjoiY2xqcHdsdjd6MDE0cTNqcDczMGU2Nm5ieiJ9.M_cXNF1Ir_hjzHZhWhK5jQ';

const Map = ({setBooking, catType = 1}) => {
    const [from, setFrom] = useState(false);
    const [to, setTo] = useState(false);

    const [distance, setDistance] = useState(false);
    const [duration, setDuration] = useState(false);
    const [price, setPrice] = useState(0);
    const [step, setStep] = useState(1)

    const [priceMod, setPriceMod] = useState(1);

    const [carType, setCarType] = useState(catType);
    const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
    const [time, setTime] = useState(new Date().toISOString().substring(11, 16));
    const [animal, setAnimal] = useState(false);
    const [addition, setAddition] = useState("");


    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [flightNumber, setFlightNumber] = useState("");


    const [final, setFinal] = useState(false);


    const map = useRef(null);
    const mapContainer = useRef(null);


    // useEffect(() => {
    //     if (carType === 1) {
    //         setPriceMod(1)
    //     } else {
    //         console.log("???")
    //         getPrice(distance)
    //     }
    // }, [carType, distance])

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/fdafilippov/cljrmfuva013q01o4cfir6087',
            interactive: false,
            center: [0.545574, 42.169869],
            zoom: 7
        });
    }, []);

    useEffect(() => {
        if (from && to) {
            if (from.center.length > 0 && to.center.length > 0) {
                fetch("https://api.mapbox.com/directions/v5/mapbox/driving/" + encodeURI(from.center[0] + "," + from.center[1] + ";" + to.center[0] + "," + to.center[1]) + "?access_token=" + mapboxgl.accessToken + "&geometries=geojson&overview=simplified&steps=false").then(async r => {
                    let result = (await r.json());
                    let coords = result.routes[0].geometry.coordinates;
                    setDistance((result.routes[0].distance / 1000).toFixed(1))
                    setDuration(timeConvert(result.routes[0].duration.toFixed(0)))
                    console.log(getPrice((result.routes[0].distance / 1000).toFixed(1)))
                    setPrice(getPrice((result.routes[0].distance / 1000).toFixed(1)))
                    console.log(result)
                    if (map.current.getSource('route')) {
                        map.current.removeLayer('route');
                        map.current.removeSource('route');
                    }

                    const geoJSON = {
                        'type': 'geojson',
                        'data': {
                            'type': 'Feature',
                            'properties': {},
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': coords
                            }
                        }
                    }

                    map.current.addSource('route', geoJSON)
                    map.current.addLayer({
                        'id': 'route',
                        'type': 'line',
                        'source': 'route',
                        'layout': {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        'paint': {
                            'line-color': '#55AE99',
                            'line-width': 5
                        }
                    });
                    const coordinates = geoJSON.data.geometry.coordinates;

                    const bounds = new mapboxgl.LngLatBounds(
                        coordinates[0],
                        coordinates[0]
                    );

                    for (const coord of coordinates) {
                        bounds.extend(coord);
                    }

                    map.current.fitBounds(bounds, {
                        padding: {top: 25, bottom:320, left: 640, right: 25}
                    });
                })
            }
        }
    }, [from, to])

    const timeConvert = (duration) => {
        var date = new Date(0);
        date.setSeconds(duration); // specify value for SECONDS here
        var timeString = date.toISOString().substring(11, 16);
        return timeString
    }

    const getPrice = (d, car = false) => {
        let pr = 0;
        if (!car) {
            car = carType;
        }
        switch (true) {
            case d <= 29:
                pr = (d * 1.87);
                setPriceMod(1.5)
                break;
            case d > 29 && d <= 69:
                pr = (d * 1.53);
                setPriceMod(1.4)
                break;
            case d > 69 && d <= 89:
                pr = (d * 1.34);
                setPriceMod(1.35)
                break;
            case d > 89 && d <= 99:
                pr = (d * 1.33);
                setPriceMod(1.35)
                break;
            case d > 99 && d <= 119:
                pr = (d * 1.4);
                setPriceMod(1.30)
                break;
            case d > 119 && d <= 199:
                pr = (d * 1.3);
                setPriceMod(1.30)
                break;
            case d > 199 && d <= 299:
                pr = (d * 1.3);
                setPriceMod(1.27)
                break;
            case d > 299 && d <= 499:
                pr = (d * 1.3);
                setPriceMod(1.25)
                break;
            case d > 499:
                pr = (d * 1.3);
                setPriceMod(1.20)
                break;
        }
        return car === 2 ? (pr < 70 ? 70 : pr.toFixed(0)) : (pr < 50 ? 50 : pr.toFixed(0))
    }

    const newOrder = () => {
        let message = `
            Имя - ${name} \n
            Фамилия - ${lastName} \n
            Номер телефона - ${phoneNumber} \n
            Email - ${email} \n
            Номер полета - ${flightNumber} \n\n
            
            Авто - ${carType === 1 ? "Стандарт" : "Бус"}\n
            Дата - ${time} ${date}\n
            Детское кресло - ${animal ? "ДА" : "НЕТ"}\n\n
            
            Точка подбора - ${from.place_name_en}\n
            Точка выгрузки - ${to.place_name_en}\n
            Растояние - ${distance} Km\n
            Расчетное время поездки - ${duration}\n
            Цена - ${carType === 2 ? Math.round(price * priceMod) : price}\n
            
            Доп. Информация - ${addition}\n
        `;

        fetch("https://barcelonataxitransfer.com/API/send_mail.php", {
            method: "POST",
            body: JSON.stringify({message: message})
        }).then(() => {
            setFinal(true)
        })

    }

    return (
        <div className={`${styles['map']}`}>
            <div ref={mapContainer} className={styles["map__container"]}/>
            <OrderContainer setBooking={setBooking}>
                {!final ?
                    step === 1 ?
                        <>
                            <OrderStepOne setFrom={setFrom} setTo={setTo} infoBlock={distance && duration && from && to && <div className={`${styles['map__orderInfos']} ${styles['map__orderInfos_mob']}`}>
                                    <div className={`${styles['map__time']}`}>
                                        <Moment format="DD/MM  H:m">
                                            {date + "T" + time}
                                        </Moment>
                                    </div>
                                    <div className={`${styles['map__info']}`}>
                                        <h3 className={`${styles['map__time-mob']}`}><span>PICKUP ADDRESS</span><br/>
                                            <Moment format="DD/MM  H:m">
                                                {date + "T" + time}
                                            </Moment>
                                        </h3>
                                        <h3><span>PICKUP ADDRESS</span><br/>{from.place_name_en}</h3>
                                        <h3><span>DROPOFF ADDRESS</span><br/>{to.place_name_en}</h3>
                                        <h4 className={`${styles['map__distance']}`}>{distance}km
                                            | {duration} approx</h4>
                                        <h4 className={`${styles['map__price']}`}>{Math.round(carType === 2 && price>70 ? (price * priceMod) : price)}€</h4>
                                    </div>
                                </div>}/>
                            <div className={`${styles['map__nextStepWrap']}`}>
                                <button className={`${styles['map__nextStep']}`} onClick={() => {
                                    setBooking(false)
                                }}> &lt; </button>
                                <button className={`${styles['map__nextStep']}`} onClick={() => {
                                    setStep(2)
                                }} disabled={price === 0}>Select options >
                                </button>
                            </div>
                        </>
                        :
                        step === 2 ?
                            <>
                                <OrderStepTwo
                                    carType={carType}
                                    setCarType={setCarType}
                                    date={date}
                                    setDate={setDate}
                                    time={time}
                                    setTime={setTime}
                                    animal={animal}
                                    setAnimal={setAnimal}
                                    addition={addition}
                                    setAddition={setAddition}
                                    price={price}
                                    priceVan={Math.round(price * priceMod)}
                                />
                                <div className={`${styles['map__nextStepWrap']}`}>
                                    <button className={`${styles['map__nextStep']}`} onClick={() => {
                                        setStep(1)
                                    }}> &lt; </button>
                                    <button className={`${styles['map__nextStep']}`} onClick={() => {
                                        setStep(3)
                                    }}>Select options >
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <OrderStepThree
                                    setStep={setStep}
                                    name={name}
                                    setName={setName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    email={email}
                                    setEmail={setEmail}
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    flightNumber={flightNumber}
                                    setFlightNumber={setFlightNumber}
                                    send={newOrder}
                                    infoBlock={distance && duration && from && to && <div className={`${styles['map__orderInfos']} ${styles['map__orderInfos_mob']}`}>
                                        <div className={`${styles['map__time']}`}>
                                            <Moment format="DD/MM  H:m">
                                                {date + "T" + time}
                                            </Moment>
                                        </div>
                                        <div className={`${styles['map__info']}`}>
                                            <h3 className={`${styles['map__time-mob']}`}><span>PICKUP ADDRESS</span><br/>
                                                <Moment format="DD/MM  H:m">
                                                    {date + "T" + time}
                                                </Moment>
                                            </h3>
                                            <h3><span>PICKUP ADDRESS</span><br/>{from.place_name_en}</h3>
                                            <h3><span>DROPOFF ADDRESS</span><br/>{to.place_name_en}</h3>
                                            <h4 className={`${styles['map__distance']}`}>{distance}km
                                                | {duration} approx</h4>
                                            <h4 className={`${styles['map__price']}`}>{Math.round(carType === 2 && price>70 ? (price * priceMod) : price)}€</h4>
                                        </div>
                                    </div>}
                                />

                            </>

                    :
                    <>
                        <OrderFinish/>
                        <button className={`${styles['map__nextStep']}`} onClick={() => {
                            setBooking(false)
                        }}>Back to home >
                        </button>
                    </>
                }
            </OrderContainer>
            {distance && duration && from && to &&
                <div className={`${styles['map__orderInfos']}`}>
                    <div className={`${styles['map__time']}`}>
                        <Moment format="DD/MM  H:m">
                            {date + "T" + time}
                        </Moment>
                    </div>
                    <div className={`${styles['map__info']}`}>
                        <h3 className={`${styles['map__time-mob']}`}><span>PICKUP ADDRESS</span><br/>
                            <Moment format="DD/MM  H:m">
                                {date + "T" + time}
                            </Moment>
                        </h3>
                        <h3><span>PICKUP ADDRESS</span><br/>{from.place_name_en}</h3>
                        <h3><span>DROPOFF ADDRESS</span><br/>{to.place_name_en}</h3>
                        <h4 className={`${styles['map__distance']}`}>{distance}km | {duration} approx</h4>
                        <h4 className={`${styles['map__price']}`}>{Math.round(carType === 2 && price>70 ? (price * priceMod) : price)}€</h4>
                    </div>
                </div>
            }
        </div>
    );
};

export default Map;