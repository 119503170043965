import React from 'react';

const ComfortIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42 38.5L49 4.66666C49 4.66666 36.75 6.99999 36.75 11.6667C36.75 14.5833 41.4166 16.3333 39.6666 18.0833C37.9166 19.8333 31.5 21.5833 31.5 26.25C31.5 29.1667 33.414 31.336 31.5 33.25C29.75 35 28 34.0833 25.0833 33.25C21.8166 32.3167 15.1666 31.5 12.8333 32.0833C10.5 32.6667 8.16659 33.8333 8.16663 37.3333C8.16664 39.2574 9.33329 41.4167 12.25 42C15.1666 42.5833 18.6666 39.6667 23.3333 39.6667C28 39.6667 33.8333 43.1667 37.3333 43.1667C40.8333 43.1667 42 38.5 42 38.5Z" stroke="#070A11" stroke-width="3" stroke-linejoin="round"/>
            <path d="M12.8334 42L9.33337 51.3333H47.8334L40.8334 42" stroke="#070A11" stroke-width="3" stroke-linejoin="round"/>
        </svg>
    );
};

export default ComfortIcon;