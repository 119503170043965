import React, {useState} from 'react';
import styles from './contact.module.scss';
import {useTranslation} from "react-i18next";
import Select from "react-select";

const options = [
    {value: 'telegram', label: <div className="select_item"><img src={"./t.png"}/> Telegram</div>},
    {value: 'whatsapp', label: <div className="select_item"><img src={"./w.png"}/> WhatsApp</div>},
    {value: 'viber', label: <div className="select_item"><img src={"./v.png"}/> Viber</div>},
    {value: 'viber', label: <div className="select_item"><img src={"./m.png"}/> Messanger</div>},
];

const Contact = () => {
    const [messanger, setMessanger] = useState(options[0]);
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const [fin, setFin] = useState(false)


    const sendEmail = () => {
        let email_message = `
            Имя - ${name} \n
            Город - ${city} \n
            Телефон - ${phone} \n
            Сообщение - ${message} \n
            Предпочитаемый мессенджер - ${messanger.value} \n  
        `;

        fetch("https://barcelonataxitransfer.com/API/send_mail.php", {
            method: "POST",
            body: JSON.stringify({message: email_message})
        }).then(() => {
            setName("")
            setCity("")
            setPhone("")
            setMessage("")
            setFin(true)
        })
    }


    const {t} = useTranslation('common');
    return (
        <div className={`${styles['contact']}`}>
            <div className={`${styles['contact__left']}`}>
                <h2>{t('contact.title.stage1')} <span>{t('contact.title.stage2')}</span>{t('contact.title.stage3')}</h2>
                <div className={`${styles['contact__cards']}`}>
                    <div className={`${styles['contact__card']}`}>
                        <a href="tel:+34672891758">+34672891758</a>
                        <a href="tel:+34647180077">+34647180077</a>
                        <a href="mailto:barcelonataxitransfers@gmail.com">barcelonataxitransfers@gmail.com</a>
                    </div>
                    <div className={`${styles['contact__card']} ${styles['contact__card_socials']}`}>
                        <div className={`${styles["contact__w"]}`}><a href="https://wa.me/34647180077"><img
                            src="./w.svg" alt=""/></a></div>
                        <div className={`${styles["contact__v"]}`}><a href="viber://contact?number=%2B34672891758"><img
                            src="./v.svg" alt=""/></a></div>
                        <div className={`${styles["contact__t"]}`}><a href="https://t.me/skyline77722"><img
                            src="./t.svg" alt=""/></a></div>
                        <div className={`${styles["contact__m"]}`}><a
                            href="https://m.facebook.com/messages/compose?ids=USER_NAME_OR_ID"><img src="./ml.svg"
                                                                                                    alt=""/></a></div>
                    </div>
                </div>
                <div className={`${styles['contact__info']}`}>
                    <img src="./logo_white.svg" alt=""/>
                    <p>{t('footer')}</p>
                    <p>© 2023 barcelonataxitransfer. All right reserved.</p>
                </div>
            </div>
            {!fin ?
                <form className={`${styles['contact__right']}`} id={"contact"} onSubmit={(e) => {
                    e.preventDefault();
                    sendEmail()
                }}>
                    <input type="text" placeholder={"Your name"} value={name} onChange={(e) => {
                        setName(e.target.value)
                    }} required/>
                    <input type="text" placeholder={"City"} value={city} onChange={(e) => {
                        setCity(e.target.value)
                    }} required/>
                    <input type="tel" placeholder={"Phone number"} value={phone} onChange={(e) => {
                        setPhone(e.target.value)
                    }} required/>
                    <textarea cols="30" rows="10" placeholder={"Write your feedback here..."} value={message}
                              onChange={(e) => {
                                  setMessage(e.target.value)
                              }} required></textarea>
                    <Select
                        value={messanger}
                        onChange={(e) => {
                            setMessanger(e);
                        }}
                        options={options}
                        isSearchable={false}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: '20px'
                            }),
                        }}
                    />
                    <button type="submit">{t('contact.send')}</button>
                </form>
                :
                <div className={`${styles['contact__right']} ${styles['contact__right_fin']}`} id={"contact"}>
                    <img src="./Check.svg" alt=""/>
                </div>
            }
        </div>
    );
};

export default Contact;