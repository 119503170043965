import React from 'react';
import styles from './testimonials.module.scss';
import TestimonialsCarousel from "../../../../components/TestimonialsCarousel/TestimonialsCarousel";
import {useTranslation} from "react-i18next";

const Testimonials = () => {
    const {t} = useTranslation('common');

    return (
        <div className={`${styles['testimonials']}`}>
            <img src="./testim_img.png" alt="image"/>
            <div  className={`${styles['testimonials__carousel']}`}>
                <h2>{t('testim.title')}</h2>
                <TestimonialsCarousel/>
            </div>
        </div>
    );
};

export default Testimonials;