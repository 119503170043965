import React, {useEffect, useState} from 'react';
import styles from './Header.module.scss';
import Select from "react-select";
import { useTranslation } from 'react-i18next'
import { slide as Menu } from 'react-burger-menu'

//60+17+23
const options = [
    { value: 'en', label: <img src={"./gb.svg"}/> },
    { value: 'es', label: <img src={"./es.svg"}/> },
    { value: 'ru', label: <img src={"./sru.svg"}/> },
];



const Header = ({setBooking, setArticle}) => {

    const { t, i18n } = useTranslation("navigation")

    const [lang, setlang] = useState(i18n.language==="en"?options[0]:i18n.language==="es"?options[1]:options[2])

    useEffect(()=>{
        let lang = localStorage.getItem("lang")
        if(lang !== null){
            setlang(i18n.language==="en"?options[0]:i18n.language==="es"?options[1]:options[2])
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(lang.value)
    },[lang])

    return (
        <div className={styles['header']}>
            <img src="./logo.svg" alt="Logo" onClick={()=>{setBooking(false); setArticle(false)}} style={{cursor:"pointer"}}/>
            <nav>
                <Select
                    value={lang}
                    onChange={(e)=> {
                        setlang(e);
                        localStorage.setItem("lang", e.value)
                    }}
                    options={options}
                    isSearchable={false}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: '20px',
                        }),
                    }}
                />
                <button className={`${styles['header__navigation']}`} onClick={()=>{setArticle("routes")}}>{t("popRoutes")}</button>
                {/*<a href={"#about"} className={`${styles['header__navigation']}`}>{t("help")}</a>*/}
                <a href={"#contact"}  className={`${styles['header__navigation']} ${styles['header__navigation_round']}`}>{t("cont")}</a>
                <button className={`${styles['header__navigation']} ${styles['header__navigation_round']} ${styles['header__navigation_orange']}`} onClick={()=>{setBooking(true)}}>{t("book")}</button>
                <Menu right>
                    <button className={`menu-item ${styles['header__navigation']}`} onClick={()=>{setArticle("routes")}}>{t("popRoutes")}</button>
                    {/*<a href={"#about"} className={`menu-item ${styles['header__navigation']}`}>{t("help")}</a>*/}
                    <a href={"#contact"}  className={`menu-item ${styles['header__navigation']} ${styles['header__navigation_round']}`}>{t("cont")}</a>
                    <button className={`menu-item ${styles['header__navigation']} ${styles['header__navigation_round']} ${styles['header__navigation_orange']}`} onClick={()=>{setBooking(true)}}>{t("book")}</button>
                </Menu>
            </nav>
        </div>
    );
};

export default Header;